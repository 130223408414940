
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93yri8I4l3CyMeta } from "/opt/simploshop/shared/core-theme/app/pages/[...slug].vue?macro=true";
import { default as indexIaYiIksDx6Meta } from "/opt/simploshop/themes/vut/app/pages/index.vue?macro=true";
import { default as loginxKzuBOTp2KMeta } from "/opt/simploshop/themes/vut/app/pages/login.vue?macro=true";
import { default as styleguideai3AevcbJfMeta } from "/opt/simploshop/themes/vut/app/pages/styleguide.vue?macro=true";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/opt/simploshop/themes/vut/app/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/login",
    component: () => import("/opt/simploshop/themes/vut/app/pages/login.vue")
  },
  {
    name: "styleguide___cs",
    path: "/styleguide",
    component: () => import("/opt/simploshop/themes/vut/app/pages/styleguide.vue")
  }
]