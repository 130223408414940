<template>
    <CoreContainer>
        <BaseContainerContent v-if="isShown" :padding="{ vertical: 'small', left: 'menu'}"> <!-- TODO remove when container is added to the layout file -->
            <div class="bg-orange flex items-start justify-between gap-4 p-5 text-white">
                <strong class="font-bold">
                    <slot />
                </strong>

                <UiButtonClose
                    variant="dark"
                    @click="close"
                />
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
import type { NoticeId } from '../../assets/ts/enums/notice'

const {
    noticeId,
} = defineProps<{
    /**
     * A unique identifier of the notice.
     * This is used to identify the notice in the cookie.
     */
    noticeId: NoticeId
}>()

/**
 * The cookie used to hide the notice.
 * Template: `hide-n425=1` to hide notice with `id 425`.
 * If the cookie is **not set** or is **not equal** to `1`, the notice is shown.
 */
const noticeCookie = useCookie<number>(`hide-n${noticeId}`, {
    // expires in 2 weeks
    maxAge: 60 * 60 * 24 * 7 * 2,
})

const isShown = computed(() => !noticeCookie.value || noticeCookie.value !== 1)

function close() {
    noticeCookie.value = 1
}

</script>
