<script lang="tsx">
import { defineComponentBaseUiSkeleton } from '@core/app/components/base/ui/BaseUiSkeleton.vue'

export type BaseUiSkeletonColors = 'white'

export default defineComponentBaseUiSkeleton<BaseUiSkeletonColors>({
    props: {
        color: {
            default: 'white',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSkeleton" as *;

@include skeleton {
    border-radius: $vut-border-radius;
}

@include skeleton--color('white') {
    @include set-skeleton-color($vut-c-white);
    @include set-skeleton-shine-color($vut-c-ivory);
}

</style>
