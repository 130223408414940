<template>
    <UiCard>
        <div class="relative flex h-full flex-col gap-3">
            <NuxtLink :to="productUrl" class="vut-product-card__link-area">
                <div class="vut-product-card__image">
                    <CoreImg
                        class="vut-product-card__image-el"
                        :src="product?.getHeroImage()"
                        :alt="product?.name"
                        :placeholder="[40, 25, 100, 4]"
                        sizes="100vw xs:100vw sm:50vw md:100vw lg:30vw xl:20vw xxl:20vw"
                        loading="lazy"
                    />
                </div>

                <span class="vut-product-card__title vut-h2 vut-link">
                    {{ product?.getName(variation) }}
                </span>
            </NuxtLink>

            <!--  CONTENT  -->
            <div class="vut-product-card__content">
                <ProductAvailability
                    :product="product"
                    :selected-variation="variation"
                />

                <ProductPrice
                    :product="product"
                    :selected-variation="variation"
                />
            </div>

            <!--  ACTIONS (cart button)  -->
            <BaseUiButton
                :to="product?.canBePurchased(variation) ? undefined : productUrl"
                :on-click="product?.canBePurchased(variation) ? addItemToCart : undefined"
                color="primary"
                :disabled="!product?.isAvailable(variation)"
                class="mx-auto mb-4"
            >
                {{ product?.canBePurchased(variation) ? $t('product.add_to_cart') : $t('product.product_detail') }}
            </BaseUiButton>
            <!--  end of ACTIONS  -->

            <div class="vut-product-card__info">
                <div v-if="product?.tags">
                    <span v-for="tag in product.tags" :key="tag.id"> <!-- TODO remove span and v-if !== 'Nové' -->
                        <BaseUiBadge
                            v-if="tag.label !== 'Nové'"
                            content-class="font-bold uppercase"
                            :style="{ background: `#${tag.background_color}`, color: `#${tag.text_color}` }"
                        >
                            {{ tag.label }}
                        </BaseUiBadge>
                    </span>
                </div>

                <!-- TODO
                <ProductFavoriteButton
                    :product="product"
                    :variation="variation"
                    class="pointer-events-auto"
                />
    -->
            </div>
        </div>
    </UiCard>
</template>

<script lang="ts" setup>
const {
    product,
    variation,
} = defineProps<{
    product?: InstanceType<typeof ProductModel> | null | undefined
    variation?: InstanceType<typeof ProductVariationModel> | null
}>()

const { addToCart } = useCart()

const productUrl = computed<string>(() => getProductUrl(product, variation) ?? '')

async function addItemToCart() {
    if (!product?.id) return

    await addToCart({
        product_id: product.id,
        amount: 1,
        variation_id: variation?.id,
    })
}
</script>

<style lang="scss" scoped>
.vut-product-card__link-area {
    flex: 1;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    padding-bottom: 0.5rem;

    text-align: center;
}

.vut-product-card__image {
    width: 100%;

    aspect-ratio: 8 / 5;

    overflow: hidden;
}

.vut-product-card__image-el {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform $vut-trans-time $vut-timing;
}

.vut-product-card__link-area:hover .vut-product-card__image-el {
    transform: scale(1.05);
}

.vut-product-card__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    text-align: center;
}

.vut-product-card__title {
    padding: 0 0.5rem;

    text-decoration: underline;
}

.vut-product-card__link-area:hover .vut-product-card__title {
    text-decoration: none;
}

.vut-product-card__info {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    display: flex;
    align-items: start;
    justify-content: space-between;

    width: 100%;

    z-index: 1;
    pointer-events: none;
}


</style>
