export function formatDateToDay(time: string): number {
    if (!time) return 0

    const date = new Date(time)
    return date.getDate()
}

export function formatDateToMonthLong(time: string, locale: string): string {
    if (!time) return ''

    const date = new Date(time)
    return date.toLocaleDateString(locale, { month: 'long' })
}
