import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { ProductListModel } from '../models/product-list.model'

class ProductListsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductListsApiService() {
    return new ProductListsApiReactiveService({
        endpoint: '/product-lists',
    }, ProductListModel)
}
