<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0C4.47984 0 0 4.47984 0 10C0 15.5202 4.47984 20 10 20C15.5202 20 20 15.5202 20 10C20 4.47984 15.5202 0 10 0ZM14.0605 14.7137C13.8911 14.7137 13.7863 14.6613 13.629 14.5685C11.1129 13.0524 8.18548 12.9879 5.29435 13.5806C5.1371 13.621 4.93145 13.6855 4.81452 13.6855C4.42339 13.6855 4.17742 13.375 4.17742 13.0484C4.17742 12.6331 4.42339 12.4355 4.72581 12.371C8.02823 11.6411 11.4032 11.7056 14.2823 13.4274C14.5282 13.5847 14.6734 13.7258 14.6734 14.0927C14.6734 14.4597 14.3871 14.7137 14.0605 14.7137ZM15.1452 12.0685C14.9355 12.0685 14.7944 11.9758 14.6492 11.8992C12.129 10.4073 8.37097 9.80645 5.02823 10.7137C4.83468 10.7661 4.72984 10.8185 4.54839 10.8185C4.11694 10.8185 3.76613 10.4677 3.76613 10.0363C3.76613 9.60484 3.97581 9.31855 4.39113 9.20161C5.5121 8.8871 6.65726 8.65323 8.33468 8.65323C10.9516 8.65323 13.4798 9.30242 15.4718 10.4879C15.7984 10.6815 15.9274 10.9315 15.9274 11.2823C15.9234 11.7177 15.5847 12.0685 15.1452 12.0685ZM16.3952 8.99597C16.1855 8.99597 16.0565 8.94355 15.875 8.83871C13.004 7.125 7.87097 6.71371 4.54839 7.64113C4.40323 7.68145 4.22177 7.74597 4.02823 7.74597C3.49597 7.74597 3.08871 7.33065 3.08871 6.79435C3.08871 6.24597 3.42742 5.93548 3.79032 5.83065C5.20968 5.41532 6.79839 5.21774 8.52823 5.21774C11.4718 5.21774 14.5565 5.83065 16.8105 7.14516C17.125 7.32661 17.3306 7.57661 17.3306 8.05645C17.3306 8.60484 16.8871 8.99597 16.3952 8.99597Z"
        />
    </svg>

)

</script>
