import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { ProductListProductModel } from '../models/product-list-product.model'

class ProductListItemsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductListProductsApiService() {
    return new ProductListItemsApiReactiveService({
        endpoint: () => '/product-list-products',
    }, ProductListProductModel)
}
