<script lang="tsx">
import { defineComponentBaseUiBadge } from '@core/app/components/base/ui/BaseUiBadge.vue'
import type { BaseVariants } from '@core/types/components'

type Sizes = 'md'

export default defineComponentBaseUiBadge<BaseVariants, Sizes>({
    props: {
        size: {
            default: 'md',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiBadge" as *;

@include badge {
    display: block;
    width: fit-content;
    text-transform: uppercase;

    background: $vut-c-gray;
    color: $vut-c-white;
}

@include badge--size('md') {
    padding: 0.25rem 0.75rem;
}

</style>
