<script lang="tsx">
import { defineComponentCoreUiTabSwitcher } from '@core/app/components/core/ui/tab/CoreUiTabSwitcher.vue'

export default defineComponentCoreUiTabSwitcher()

</script>

<style lang="scss" scoped>
@use '@core-scss/components/CoreUiTabSwitcher.scss' as *;

@include tab-switcher {
    padding-bottom: 10px;
}

@include tab {
    flex: auto;

    position: relative;
    padding: 0.75rem;
    border: 1px solid $vut-c-border;

    text-transform: uppercase;
    font-family: $vut-ff-secondary;
    letter-spacing: 0.08em;

    transition: color $vut-trans-time $vut-timing, background $vut-trans-time $vut-timing;

    &:not(:last-child) {
        border-right: none;
    }

    &:hover {
        color: $vut-c-white;
        background: $vut-c-secondary;
    }

    @include more-than(lg) {
        flex: 1;
    }
}

@include tab--active {
    background: $vut-c-ivory;
    border: 1px solid $vut-c-silver;

    z-index: 1;

    cursor: not-allowed;

    transition: background 0s $vut-timing;

    &::after {
        content: '';
        position: absolute;
        bottom: -6px;

        height: 10px;
        width: 10px;

        background: $vut-c-ivory;
        border-bottom: 1px solid $vut-c-silver;
        border-right: 1px solid $vut-c-silver;

        transform: rotate(45deg);
    }

    &:hover {
        color: inherit;
        background: $vut-c-ivory;
    }

    & + * {
        border-left-color: $vut-c-silver;
    }
}

</style>
