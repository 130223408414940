<template>
    <ul class="flex flex-wrap gap-2.5 md:col-span-2 lg:col-span-1 lg:justify-end">
        <li>
            <NuxtLink to="https://www.facebook.com/VUTvBrne"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.facebook')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <svg xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="100%"
                     height="20"
                     fill="#1675CF"
                >
                    <use xlink:href="/images/icons/icons-svg.svg#icon-facebook"
                         x="0"
                         y="0"
                         width="100%"
                         height="100%"
                    />
                </svg>
            </NuxtLink>
        </li>
        <li>
            <NuxtLink to="https://x.com/VUTvBrne"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.x')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <IconXSocial width="20" height="100%" />
            </NuxtLink>
        </li>
        <li>
            <NuxtLink to="https://www.youtube.com/VUTvBrne"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.youtube')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <svg xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="20"
                     height="100%"
                     fill="#E4002B"
                >
                    <use xlink:href="/images/icons/icons-svg.svg#icon-youtube"
                         x="0"
                         y="0"
                         width="100%"
                         height="100%"
                    />
                </svg>
            </NuxtLink>
        </li>
        <li>
            <NuxtLink to="https://www.instagram.com/vutvbrne/"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.instagram')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <svg xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="20"
                     height="100%"
                     fill="#C43F8A"
                >
                    <use xlink:href="/images/icons/icons-svg.svg#icon-instagram"
                         x="0"
                         y="0"
                         width="100%"
                         height="100%"
                    />
                </svg>
            </NuxtLink>
        </li>
        <li>
            <NuxtLink to="https://www.threads.net/@vutvbrne"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.threads')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <IconThreads width="100%" height="20" />
            </NuxtLink>
        </li>
        <li>
            <NuxtLink to="https://www.linkedin.com/school/vysoké-učení-technické-v-brně"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.linkedin')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <svg xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="20"
                     height="100%"
                     fill="#0E76A8"
                >
                    <use xlink:href="/images/icons/icons-svg.svg#icon-linkedin"
                         x="0"
                         y="0"
                         width="100%"
                         height="100%"
                    />
                </svg>
            </NuxtLink>
        </li>
        <li>
            <NuxtLink to="https://open.spotify.com/show/1ZEmKuf1Uk9FNFUHsN61H5"
                      target="_blank"
                      class="vut-socials__item"
                      :aria-label="`${$t('accessibility.socials.go_to_our', [$t('accessibility.socials.spotify')])} (${$t('accessibility.opens_in_new_tab')})`"
            >
                <IconSpotify width="20" height="100%" fill="#1ED760" />
            </NuxtLink>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
/* TODO UiCard ? */
.vut-socials__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid $vut-c-platinum;
    transition: border-color $vut-trans-time $vut-timing;

    &:hover {
        border-color: $vut-c-silver;
    }
}
</style>
