<template>
    <CoreSwiperNavigation v-slot="{ slidePrev, slideNext }">
        <div v-bind="$attrs">
            <button type="button"
                    class="vut-swiper__nav vut-swiper__nav--prev"
                    @click="slidePrev"
            >
                <IconChevron
                    right
                    width="1rem"
                    :aria-label="$t('accessibility.icons.left_arrow')"
                />
            </button>
            <button type="button"
                    class="vut-swiper__nav vut-swiper__nav--next"
                    @click="slideNext"
            >
                <IconChevron
                    left
                    width="1rem"
                    :aria-label="$t('accessibility.icons.right_arrow')"
                />
            </button>
        </div>
    </CoreSwiperNavigation>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.vut-swiper__nav {
    position: absolute;
    top: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 2.5rem;
    width: 2.5rem;

    border-radius: 100vmax;
    color: $vut-c-secondary;
    background: $vut-c-white;
    border: 2px solid $vut-c-white;

    transform: translateY(-50%);

    z-index: 2;

    @include accessibility-focus-outline-offset;

    transition: background $vut-trans-time $vut-timing, color $vut-trans-time $vut-timing, border $vut-trans-time $vut-timing;

    &:hover {
        border: 2px solid $vut-c-secondary;
    }
}

.vut-swiper__nav--disabled {
    display: none;
}

.vut-swiper__nav--prev {
    left: 1rem;
}

.vut-swiper__nav--next {
    right: 1rem;
}
</style>
