<script lang="tsx">
import { defineComponentBaseUiButton } from '@core/app/components/base/ui/BaseUiButton.vue'
import type { BaseVariants } from '@core/types/components'

type Colors = 'primary' | 'secondary'
type Variants = BaseVariants | 'plain' /* solid | outlined */
type Sizes = 'small' | 'medium' | 'large' | 'giant' | 'ultra' | 'none'

export default defineComponentBaseUiButton<Colors, Variants, Sizes>({
    props: {
        size: {
            default: 'medium',
        },
        color: {
            default: 'primary',
        },
        variant: {
            default: 'solid',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton.scss" as *;

@include button {
    font-size: 0.875rem;
    line-height: 100%;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: $vut-ff-secondary;

    border-radius: $vut-border-radius;

    transition: background-color $vut-trans-time $vut-timing, color $vut-trans-time $vut-timing, border $vut-trans-time $vut-timing;
}

@include button--disabled {
    background-color: $vut-c-gray;
}

@include button--variant(outlined) {
    border: 2px solid var(--outline-color);

    @include content {
        margin: -2px;
    }
}

@include button--variant(plain) {
    border: 2px solid var(--outline-color);

    @include content {
        margin: -2px;
    }
}

// --------------------------------------------------------------------
// Button sizes
@include button--size(small) {
    padding: 0.8125rem 1.875rem;
}

@include button--size(medium) {
    padding: 1.125rem 2.5rem;
}

@include button--size(large) {
    padding: 1.5625rem 2.5rem;
}


// --------------------------------------------------------------------
// Button colors
@include button--color('primary') {
    @include button--variant(solid, '&') {
        background-color: $vut-c-primary;
        color: $vut-c-white;

        @include button--hover('&') {
            background-color: $vut-c-primary-darker;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $vut-c-white;
        color: $vut-c-primary;

        --outline-color: #{$vut-c-primary};

        @include button--hover('&') {
            background-color: $vut-c-primary;
            color: $vut-c-white;
        }
    }
}

@include button--color('secondary') {
    @include button--variant(solid, '&') {
        background-color: $vut-c-secondary;
        color: $vut-c-white;

        @include button--hover('&') {
            background-color: $vut-c-secondary-darker;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $vut-c-white;
        color: $vut-c-secondary;

        --outline-color: #{$vut-c-secondary};

        @include button--hover('&') {
            background-color: $vut-c-secondary;
            color: $vut-c-white;
        }
    }

    @include button--variant(plain, '&') {
        background-color: $vut-c-white;
        color: $vut-c-secondary;

        --outline-color: #{$vut-c-white};

        @include button--hover('&') {
            --outline-color: #{$vut-c-secondary};
        }
    }
}
</style>
