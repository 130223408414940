<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <CoreContainer tag="div">
        <BaseContainerContent
            :padding="{ vertical: 'large' }"
            width="narrow"
            tag="article"
        >
            <h1 class="vut-h1 mb-7">
                {{ articlesResponse?.name }}
            </h1>

            <CoreImg
                :src="articlesResponse?.imageUrl"
                :alt="articlesResponse?.name"
                :width="1000"
                sizes="100vw xs:100vw sm:100vw md:100vw lg:100vw xl:50vw xxl:50vw"
                fit="contain"
                class="mb-11 aspect-video size-full object-cover"
            />

            <p class="vut-text-large mb-14 font-bold">
                {{ articlesResponse?.perex }}
            </p>

            <div class="vut-wysiwyg-text"
                 v-html="articlesResponse?.content"
            />
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
const { page } = useCurrentPage()

const [
    { item: articlesResponse },
] = await Promise.all([
    useArticlesApiService()
        .embed([
            ArticleModel.EMBED_IMAGE_URL,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
])
</script>
