<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 -1 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_3312)">
            <path d="M12 0L10 -8.74228e-08L6.001 4L2 -4.37114e-07L0 -5.24537e-07L6.001 6L7.001 5L12 0Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_1_3312">
                <rect width="6" height="12" transform="translate(12) rotate(90)" />
            </clipPath>
        </defs>
    </svg>
)
</script>
