import routerOptions0 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/simploshop/shared/core-theme/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}