<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 18 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.1223 9.15226C13.2139 9.18975 13.2972 9.23141 13.3847 9.2689C14.6011 9.85628 15.4926 10.7353 15.9592 11.8267C16.6132 13.3472 16.6757 15.8175 14.6969 17.7921C13.1889 19.3001 11.3518 19.9792 8.75651 20H8.74401C5.81962 19.9792 3.57426 18.996 2.06207 17.0756C0.716517 15.3676 0.0249948 12.989 0 10.0104V9.99792V9.98959C0.020829 7.01104 0.712352 4.63237 2.0579 2.92439C3.57009 1.00396 5.81962 0.020829 8.74401 0H8.75651C11.6851 0.020829 13.9596 0.999792 15.5176 2.91189C16.2841 3.85753 16.8507 4.99479 17.2089 6.31535L15.5259 6.76526C15.2302 5.69048 14.7844 4.77401 14.1845 4.04082C12.9681 2.54947 11.1435 1.78296 8.74818 1.7663C6.37367 1.78713 4.57821 2.54947 3.40762 4.03249C2.31618 5.4197 1.74964 7.42762 1.72881 9.99792C1.74964 12.5682 2.31618 14.5761 3.40762 15.9675C4.57405 17.4505 6.37367 18.2129 8.74818 18.2337C10.8894 18.217 12.3058 17.7088 13.4847 16.5299C14.8302 15.1885 14.8052 13.5388 14.3762 12.5349C14.1221 11.9433 13.6638 11.4518 13.0473 11.081C12.8931 12.2016 12.5557 13.0931 12.0183 13.7805C11.306 14.6886 10.2937 15.1802 8.98979 15.251C8.00667 15.3051 7.06103 15.0677 6.32785 14.5845C5.46136 14.0096 4.95314 13.1348 4.89898 12.1141C4.79483 10.1021 6.38617 8.65653 8.86482 8.51489C9.7438 8.4649 10.5686 8.5024 11.331 8.63153C11.231 8.015 11.0269 7.52343 10.7228 7.16517C10.3062 6.67778 9.65632 6.42783 8.79817 6.42366H8.76901C8.07748 6.42366 7.14434 6.61529 6.54864 7.51927L5.1156 6.53614C5.91543 5.32389 7.211 4.65736 8.77317 4.65736H8.8065C11.4143 4.67403 12.9681 6.30285 13.1264 9.14393L13.1181 9.15226H13.1223ZM6.62362 12.0183C6.67778 13.0639 7.80671 13.5513 8.89815 13.4889C9.96459 13.4305 11.1727 13.014 11.3768 10.4395C10.8269 10.3187 10.2187 10.2562 9.56884 10.2562C9.36888 10.2562 9.16892 10.2604 8.96896 10.2729C7.18184 10.3728 6.58613 11.2393 6.62779 12.0142L6.62362 12.0183Z"
        />
    </svg>
)

</script>
