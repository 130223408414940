<template>
    <div class="vut-full-layout">
        <CoreSkipToMainContent />

        <!--  NAVBAR  -->
        <!--        <NavbarComponent />-->

        <slot />

        <!--  FOOTER  -->
        <FooterComponent class="mt-auto" />
    </div>
</template>

<script lang="ts" setup>

const { page } = useCurrentPage()

const { t } = useI18n()

useHead({
    link: [
        // Preconnect to the image CDN
        // {
        //     rel: 'preconnect',
        //     href: 'https://vut.dev.simploshop.com',
        // },
    ],
})

useSetPageSeoProperties(page, {
    title: 'VUT shop',
    description: t('meta.description'),
})

</script>

<style lang="scss" scoped>

.vut-full-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
}

</style>
