<template>
    <CoreContainer :aria-label="$t('accessibility.newsletter')">
        <BaseContainerContent class="flex flex-col gap-4 xl:flex-row" :padding="{ vertical: 'none' }">
            <h3 class="vut-h3 flex-1">
                Buď v obraze! Dostávej aktuality z VUT
                <br>
                přímo na mail a už nic nepropásni!
            </h3>

            <NewsletterSubscribeComponent class="flex-1" />
        </BaseContainerContent>
    </CoreContainer>
</template>
