<template>
    <footer class="vut-footer">
        <FooterNewsletter class="vut-footer__newsletter" />

        <CoreContainer :aria-label="$t('accessibility.footer')" class="vut-footer__menus">
            <BaseContainerContent :padding="{ horizontal: ['none', 'md:normal'], vertical: 'none' }" class="grid md:grid-cols-4 md:gap-12 xl:gap-32">
                <FooterMenu :menu="transformedFooter1MenuData" />
                <FooterMenu :menu="transformedFooter2MenuData" />
                <FooterMenu :menu="transformedFooter3MenuData" />
                <FooterMenu :menu="transformedFooter4MenuData" />
            </BaseContainerContent>
        </CoreContainer>

        <CoreContainer :aria-label="$t('accessibility.footer')" class="vut-footer__main">
            <BaseContainerContent :padding="{ vertical: 'none' }" class="grid items-center gap-6 md:grid-cols-2 lg:grid-cols-3">
                <NuxtLink :to="localePath('/')" class="w-fit">
                    <!-- TODO - use CoreImg (now not working correctly with svg) -->
                    <NuxtImg src="/images/vut.svg"
                             alt=""
                             loading="lazy"
                             class="h-20 w-full md:h-24"
                    />
                </NuxtLink>

                <div>
                    <h5 class="vut-h5 mb-3">
                        Vysoké učení technické v Brně
                    </h5>

                    <div class="flex gap-7">
                        <address>
                            Antonínská 548/1
                            <br>
                            601 90 Brno
                        </address>
                        <address>
                            <NuxtLink to="www.vutbr.cz" class="vut-link vut-link--primary vut-link--underline font-bold">
                                www.vutbr.cz
                            </NuxtLink>
                            <br>
                            <a href="mailto:navut@vutbr.cz" class="vut-link vut-link--primary vut-link--underline font-bold">
                                navut@vutbr.cz
                            </a>
                        </address>
                    </div>
                </div>

                <FooterSocials />
            </BaseContainerContent>
        </CoreContainer>

        <FooterSignature class="vut-footer__signature vut-text-small" />
    </footer>
</template>

<script setup lang="ts">
import FooterSocials from '~/components/footer/FooterSocials.vue'

const localePath = useLocalePath()

const footerMenuItems = [
    {
        name: 'Možnosti dopravy',
        urls: '/todo', /* TODO */
    }, {
        name: 'Možnosti platby',
        urls: '/todo',
    }, {
        name: 'Reklamace a vrácení',
        urls: '/todo',
    }, {
        name: 'Styleguide',
        urls: '/styleguide', /* TODO */
    },
]

const transformedFooter1MenuData = computed(() => {
    return {
        title: 'Nákup',
        links: footerMenuItems.slice(0, 6).map(item => ({
            label: item.name || '',
            url: item.urls || '',
        })),
    }
})

const transformedFooter2MenuData = computed(() => {
    return {
        title: 'Objednávky',
        links: footerMenuItems.slice(0, 6).map(item => ({
            label: item.name || '',
            url: item.urls || '',
        })),
    }
})

const transformedFooter3MenuData = computed(() => {
    return {
        title: 'O univerzitě',
        links: footerMenuItems.slice(0, 6).map(item => ({
            label: item.name || '',
            url: item.urls || '',
        })),
    }
})

const transformedFooter4MenuData = computed(() => {
    return {
        title: 'Spolupráce',
        links: footerMenuItems.slice(0, 6).map(item => ({
            label: item.name || '',
            url: item.urls || '',
        })),
    }
})
</script>

<style lang="scss" scoped>
/* TODO - maybe change padding to BaseContainerContent paddings */
.vut-footer__newsletter {
    padding: 2.25rem 0;
    border-top: hr-line();

    @include more-than(md) {
        padding: 4.25rem 0;
    }
}

.vut-footer__menus {
    @include more-than(md) {
        padding: 3rem 0;
        border-top: hr-line();
    }
}

.vut-footer__main {
    padding: 1rem 0 2.25rem;

    @include more-than(md) {
        padding: 3rem 0;
        border-top: hr-line();
    }
}

.vut-footer__signature {
    padding: 1.625rem 0;
    border-top: hr-line();

    @include more-than(md) {
        padding: 1.5rem 0;
    }
}
</style>
