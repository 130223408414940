<template>
    <button type="button"
            class="vut-close-btn"
            :aria-label="$t('labels.close')"
    >
        <IconCross size="sm" />
    </button>
</template>


<style lang="scss" scoped>
.vut-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    margin: -0.5rem;
    flex-shrink: 0;
}
</style>
