<template>
    <CoreUiForm
        v-slot="{ formData, isFormSubmitting }"
        :schema="schema"
        :on-submit="handleNewsletterFormSubmit"
        class="w-full"
        notify-on-error="all"
    >
        <BaseUiElementGroup class="flex-col gap-3 sm:flex-row sm:gap-0">
            <BaseUiInput
                v-model:form="formData.email"
                :placeholder="$t('labels.your_email')"
                type="email"
                @input="sentSuccessfully = false"
            />

            <BaseUiButton
                color="secondary"
                type="submit"
                :loading="isFormSubmitting"
                class="flex-none"
            >
                {{ $t('sections.newsletter.subscribe') }}
            </BaseUiButton>
        </BaseUiElementGroup>
    </CoreUiForm>
</template>

<script lang="ts" setup>

import { NewsletterType } from '@sim-api-enums/newsletter'
import { z } from 'zod'

const { t } = useI18n()
const { notifySuccess } = useNotifications()

const sentSuccessfully = ref<boolean>(false)
const newslettersService = useNewslettersApiService()

async function handleNewsletterFormSubmit(payload: schema) {
    sentSuccessfully.value = false

    await newslettersService.post({
        email: payload.email,
        type: payload.type,
        gender_oriented_product_preference: null,
    })

    sentSuccessfully.value = true

    notifySuccess(t('_core_theme.notifications.newsletter_success'))
}

const schema = z.object({
    email: zStringMailType,
    type: z.number().default(NewsletterType.GENERAL),
})

export type schema = z.infer<typeof schema>
</script>

<style lang="scss" scoped>

:deep(.sim-newsletter-subscribe__input) {
    height: 100%;
    padding: 0 1.25rem;
}
</style>
