<template>
    <div class="vut-footer__menu">
        <BaseUtilCollapseButton
            v-model="collapseData"
            class="px-4 py-5 md:px-0"
        >
            <h5 class="vut-h5">
                {{ menu.title }}
            </h5>

            <IconChevron height="6"
                         :class="[
                             'vut-footer__menu-icon',
                             collapseData.isExpanded && 'vut-footer__menu-icon--active'
                         ]"
            />
        </BaseUtilCollapseButton>

        <BaseUtilCollapse
            v-model="collapseData"
            class="px-4 md:px-0"
        >
            <ul class="mb-8 flex flex-col gap-3 sm:mt-2">
                <li v-for="(link, index) in menu.links" :key="index">
                    <NuxtLink :to="link.url ?? ''" class="vut-link hover:underline">
                        {{ link.label }}
                    </NuxtLink>
                </li>
            </ul>
        </BaseUtilCollapse>
    </div>
</template>

<script lang="ts" setup>
const {
    menu,
} = defineProps<{
    menu: { title: string, links: { url: string, label: string }[] }
}>()

const { collapseData } = useCollapse({
    expandedBreakpoint: 'md',
})
</script>

<style lang="scss" scoped>
.vut-footer__menu {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $vut-c-border;

    @include more-than(md) {
        border-top: unset;
    }
}

.vut-footer__menu-icon {
    margin-left: auto;
    color: $vut-c-secondary;
    transition: transform $vut-trans-time $vut-timing;

    @include more-than(md) {
        display: none;
    }
}

.vut-footer__menu-icon--active {
    transform: rotate(-180deg);
}
</style>
