<script lang="tsx">
import { defineComponentBaseContainerContent } from '@core/app/components/base/container/BaseContainerContent.vue'

type PaddingSizes = 'small' | 'normal' | 'large' | 'menu'
type ContentWidths = 'narrow'

export default defineComponentBaseContainerContent<PaddingSizes, ContentWidths>({
    props: {
        padding: {
            default: 'normal',
        },
        width: {
            default: 'normal',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseContainerContent" as *;

// Content widths
@include define-max-width('normal', 1440px);
@include define-max-width('narrow', 1000px);

// Small padding size
@include define-padding-size('small', 1.25rem, 1rem, 1rem, 1.25rem);
@include define-padding-size-after-breakpoint('small', 'md', 1.25rem, 5rem, 1.25rem, 5rem);

// Default padding size
@include define-padding-size('normal', 1rem, 1rem, 1rem, 1rem);
@include define-padding-size-after-breakpoint('normal', 'md', 2.25rem, 5rem, 2.25rem, 5rem);

// Large padding size
@include define-padding-size('large', 3rem, 1rem, 3rem, 1rem);
@include define-padding-size-after-breakpoint('large', 'md', 4rem, 5rem, 4rem, 5rem);

// Menu padding size (TODO)
@include define-padding-size('menu', 0, 1rem, 0, 1rem);
@include define-padding-size-after-breakpoint('menu', 'md', 0, 324px, 0, 324px);

</style>
