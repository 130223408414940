<script lang="tsx">
import { defineComponentBaseUiInput } from '@core/app/components/base/ui/BaseUiInput.vue'

export default defineComponentBaseUiInput()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiInput" as *;

@include input {
    background: $vut-c-white;
    border-radius: $vut-border-radius;
    border: 1px solid $vut-c-border;
    padding: 0.75rem 1.25rem;

    @include vut-text;

    &:focus-within {
        border: 1px solid $vut-c-secondary;
    }
}

@include placeholder {
    color: $vut-c-gray;
}
</style>
