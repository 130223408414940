<template>
    <CoreContainer :aria-label="$t('accessibility.copyright')">
        <BaseContainerContent :padding="{ vertical: 'none' }" class="grid gap-x-7 sm:grid-cols-2 lg:grid-cols-3">
            <div>
                Copyright © {{ year }} VUT

                <CoreCreatedBySimplo orientation="row" variant="dark" :height="15" />
            </div>

            <div>
                <NuxtLink to="/todo" class="vut-link vut-link--primary vut-link--underline block w-fit">
                    Prohlášení o přístupnosti
                </NuxtLink>
                <NuxtLink to="/todo" class="vut-link vut-link--primary vut-link--underline block w-fit">
                    Informace o používání cookies
                </NuxtLink>
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
const year = useState(() => new Date().getFullYear())
</script>
