<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <CoreContainer>
        <BaseContainerContent>
            TODO
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>
