import payload_plugin_9vsmjLlP23 from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import model_serialization_mFxH9Vcc2b from "/opt/simploshop/shared/modules/simploshop-api/src/runtime/plugins/model-serialization.ts";
import response_serialization_9Nb96RIzeZ from "/opt/simploshop/shared/modules/simploshop-api/src/runtime/plugins/response-serialization.ts";
import api_response_error_serialization_AEPGpW8H3k from "/opt/simploshop/shared/modules/composable-api/src/runtime/plugins/api-response-error-serialization.ts";
import revive_payload_client_0GLduFAaCj from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nHmst2hQyC from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dsyAFOVLsZ from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import lite_youtube_client_kDCiZBs6WY from "/opt/simploshop/shared/core-simploshop/app/plugins/lite-youtube.client.ts";
import v_visibility_N214iPkHWb from "/opt/simploshop/shared/core-simploshop/app/plugins/v-visibility.ts";
import payload_client_PC2Npr9bTQ from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fUiGLhMeQY from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Ym09SJjEwE from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_g5lnD70oNg from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Op11a7NcSp from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/opt/simploshop/themes/vut/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_WIxz6rF6y6 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_xgeezg7sd2zqpmhoxfbkfk3mhy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_7XnrSX5jra from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_rollup@4_asqtqjvtj62bku2zorwwwgtmwa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZnfZfW0TY0 from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_rollup@4_asqtqjvtj62bku2zorwwwgtmwa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_oJ0WmhsZd2 from "/opt/simploshop/shared/core-theme/app/plugins/prod/sentry.client.ts";
import auth_client_jxSWoRTMlq from "/opt/simploshop/shared/core-theme/app/plugins/auth.client.ts";
import cart_client_OfZaMaRZWE from "/opt/simploshop/shared/core-theme/app/plugins/cart.client.ts";
import global_events_client_f10UUQk6Lm from "/opt/simploshop/shared/core-theme/app/plugins/global-events.client.ts";
import properties_sGCK2d0mhj from "/opt/simploshop/shared/core-theme/app/plugins/properties.ts";
import routing_0TVo85qKvv from "/opt/simploshop/shared/core-theme/app/plugins/routing.ts";
import setup_zrAIVq6G18 from "/opt/simploshop/shared/core-theme/app/plugins/setup.ts";
export default [
  payload_plugin_9vsmjLlP23,
  model_serialization_mFxH9Vcc2b,
  response_serialization_9Nb96RIzeZ,
  api_response_error_serialization_AEPGpW8H3k,
  revive_payload_client_0GLduFAaCj,
  unhead_nHmst2hQyC,
  router_dsyAFOVLsZ,
  lite_youtube_client_kDCiZBs6WY,
  v_visibility_N214iPkHWb,
  payload_client_PC2Npr9bTQ,
  navigation_repaint_client_fUiGLhMeQY,
  check_outdated_build_client_Ym09SJjEwE,
  chunk_reload_client_g5lnD70oNg,
  plugin_vue3_Op11a7NcSp,
  components_plugin_zlvi6dcIsi,
  prefetch_client_WIxz6rF6y6,
  switch_locale_path_ssr_7XnrSX5jra,
  i18n_ZnfZfW0TY0,
  sentry_client_oJ0WmhsZd2,
  auth_client_jxSWoRTMlq,
  cart_client_OfZaMaRZWE,
  global_events_client_f10UUQk6Lm,
  properties_sGCK2d0mhj,
  routing_0TVo85qKvv,
  setup_zrAIVq6G18
]