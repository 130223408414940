<script lang="tsx">
import { type PropType, type SlotsType } from 'vue'
import type { FormAutocomplete, FormFieldObject, TextFormInput } from '@core-types/form'
import type {
    BaseVariants,
    ComponentOverrideOptions,
    SizeProp
} from '@core-types/components'
import { getBaseFormElementRuntimeProps } from '@core/app/utils/form'
import type { BaseFormElementProps } from '@core/app/utils/form'

export type BaseUiInputProps<Colors extends string, Variants extends string, Sizes extends string> = {
    color?: Colors
    variant?: Variants
    size?: Sizes

    /**
     * The type of the input.
     * This prop is similar but not identical to the HTML `type` attribute.
     * It decides whether to show a text input or a textarea,
     * whether to require `autocomplete` for password inputs, etc.
     *
     * @default 'text'
     */
    type?: TextFormInput
    /**
     * The autocomplete attribute for the input.
     * Has automatic checks for password inputs in dev mode in order not to be forgotten.
     */
    autocomplete?: FormAutocomplete
    /**
     * The placeholder text for the input.
     */
    placeholder?: string
    min?: number
    max?: number
    step?: number
    /**
     * Whether to **automatically focus the input** when it is **mounted**.
     */
    autofocus?: boolean
    /**
     * Whether the input is clearable or not.
     * A clearable input has a clear button that clears the input value.
     */
    clearable?: boolean
    /**
     * Whether to show a toggle to view the input value as plain text
     * when the input type is password.
     */
    showPasswordToggle?: boolean
    /**
     * Whether to automatically resize the input to fit the content.
     * This is available for textarea inputs (type="textarea") and also for other input types,
     * where setting the number of rows is not considered,
     * and it adjusts its width based on the current number of characters.
     */
    autoresize?: boolean
    /**
     * The number of rows to show for the textarea.
     * This is only available for textarea inputs. (`type="textarea"`)
     *
     * @default 1
     */
    rows?: number

    /**
     * Whether to adjust the padding of the input based on the presence of the leading and trailing slots.
     * If set to `static`, the padding will be the same regardless of the presence of the slots.
     * If set to `dynamic`, the padding will be adjusted based on the presence of the slots to use the vertical padding
     * on the horizontal sides as well for the sides with a slot.
     * @default 'dynamic'
     */
    paddingBehavior?: 'static' | 'dynamic'
    /**
     * Whether to use equal padding on all sides of the input.
     */
    square?: boolean

    /**
     * Specifies which slots include elements that can be interacted with.
     * By default, slots are not considered interactive and thus have
     * `aria-hidden="true"` & `pointer-events: none` applied.
     */
    actions?: 'leading' | 'trailing' | 'both'
} & BaseFormElementProps<string | number>

export const getBaseUiInputRuntimeProps = <TProps extends BaseUiInputProps<any, any, any>, Colors extends string, Variants extends string, Sizes extends string>(options?: ComponentOverrideOptions<any, TProps>)
: RuntimeProps<Pick<TProps, keyof BaseUiInputProps<any, any, any>>> =>
    ({
        ...defineRuntimeProps<BaseUiInputProps<Colors, Variants, Sizes>>({
            // @ts-ignore
            color: { type: String },
            // @ts-ignore
            variant: { type: String },
            // @ts-ignore
            size: { type: String },
            // @ts-ignore
            type: { type: String, default: 'text' },
            // @ts-ignore
            autocomplete: { type: String },
            placeholder: { type: String },
            min: { type: Number },
            max: { type: Number },
            step: { type: Number },
            autofocus: { type: Boolean },
            clearable: { type: Boolean },
            showPasswordToggle: { type: Boolean },
            autoresize: { type: Boolean },
            rows: { type: Number, default: 1 },
            // @ts-ignore
            paddingBehavior: { type: String, default: 'dynamic' },
            square: { type: Boolean },
            // @ts-ignore
            actions: { type: String },
        }, options),
        ...getBaseFormElementRuntimeProps([String, Number] as PropType<BaseUiInputProps<Colors, Variants, Sizes>['modelValue']>, options),
    })

type BaseUiInputSlots<Colors extends string, Variants extends string, Sizes extends string> = {
    default: {}
    leading: {}
    trailing: {}
    clear: {}
    passwordToggle: {
        isVisible: boolean
    }
}

type ComponentOptions = {

}

export function defineComponentBaseUiInput<
    Colors extends string,
    Variants extends string = BaseVariants,
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiInputProps<Colors, Variants, Sizes>, BaseUiInputSlots<Colors, Variants, Sizes>>) {
    return defineComponent(
        (props: BaseUiInputProps<Colors, Variants, Sizes>, ctx) => {
            const { injected } = useCoreUiFormProvide<any>()

            const inputValue = computed<string | number | undefined>({
                get() {
                    return props.modelValue
                },
                set(val: string | number | undefined) {
                    if (val === undefined) return
                    ctx.emit('update:modelValue', val)
                },
            })
            const inputValueModifiers = computed<Record<string, boolean>>(() => props.modelModifiers || {})

            const formInputValue = computed<FormFieldObject<string | number> | undefined>({
                get() {
                    return props.form
                },
                set(val: FormFieldObject<string | number> | undefined) {
                    if (val === undefined) return
                    ctx.emit('update:form', val)
                },
            })

            const { t } = useI18n()

            const { injected: baseUiElementGroupInjected } = useBaseUiElementGroupProvide()

            const isDisabled = computed(() => props.disabled || props.loading)

            const isLeadingSlotRendered = computed<boolean>(() => ctx.slots.leading !== undefined || !!options?.slots?.leading)
            const isTrailingSlotRendered = computed<boolean>(() => !!(ctx.slots.trailing !== undefined || options?.slots?.trailing || hasBuiltInActionsInTrailingSlot.value))
            const hasBuiltInActionsInTrailingSlot = computed<boolean>(() => !!(props.clearable || props.showPasswordToggle))
            const hasContent = computed<boolean>(() => !!internalValue.value)

            const isPasswordVisible = ref<boolean>(false)
            const passwordVisibilityMessage = ref<string | null>(null)

            const inputType = computed<TextFormInput>(() => {
                if (props.type === 'password' && isPasswordVisible.value) {
                    return 'text'
                }
                return props.type ?? 'text'
            })

            const isNumberInput = computed<boolean>(() => inputType.value === 'number')

            function setPasswordVisibility(value: boolean, updateMessage: boolean = true) {
                isPasswordVisible.value = value
                if (updateMessage) {
                    passwordVisibilityMessage.value = value
                        ? t('_core_simploshop.accessibility.password_is_visible')
                        : t('_core_simploshop.accessibility.password_is_hidden')
                }
            }

            function _hidePasswordBeforeSubmit() {
                setPasswordVisibility(false, false)
            }

            let wasPasswordVisibilityCallbackRegistered = false
            function togglePasswordVisibility() {
                setPasswordVisibility(!isPasswordVisible.value)

                if (!wasPasswordVisibilityCallbackRegistered) {
                    injected?.registerCallback?.('before-submit', _hidePasswordBeforeSubmit)
                    wasPasswordVisibilityCallbackRegistered = true
                }
            }

            const describedBy = computed<string | undefined>(() => {
                if (!props.descriptionId) return
                return Array.isArray(props.descriptionId) ? props.descriptionId.join(' ') : props.descriptionId
            })

            const _internalId = useId()
            const inputId = computed(() => props.id ?? _internalId)

            const setInputValue = (value: string | number) => {
                if (inputValue.value !== undefined) {
                    inputValue.value = value
                    // do not set form input value if normal `v-model` is used
                    // this is needed to prevent a bug, but I can't remember which one :(
                    return
                }
                if (formInputValue.value === undefined) return
                formInputValue.value.__v = value
            }

            const lazyValue = ref<string | number | undefined>(inputValue.value)
            if (inputValueModifiers.value['lazy']) {
                watch(inputValue, (value) => {
                    lazyValue.value = value
                })
            }

            const internalValue = computed<string | number>({
                get() {
                    // make normal `v-model` have higher priority
                    if (inputValue.value !== undefined) {
                        // if the v-model has a .lazy modifier, use the lazy value
                        if (inputValueModifiers.value['lazy']) {
                            return lazyValue.value ?? ''
                        }
                        // otherwise use the normal v-model value
                        return inputValue.value
                    }
                    // otherwise use the form input value binding
                    if (formInputValue.value === undefined) errorLog(...['[BaseUiInput]: no v-model value provided', getCurrentInstance()?.vnode.el].filter(Boolean))
                    return formInputValue.value?.__v ?? ''
                },
                set(value: string | number) {
                    // if the v-model has a .lazy modifier, do not set the input value directly
                    // the value is set in the change event
                    if (inputValueModifiers.value['lazy']) {
                        lazyValue.value = value
                        return
                    }
                    // otherwise set the value
                    setInputValue(value)
                },
            })

            const errorMessage = computed<string | null>(() => {
                if (!formInputValue.value || !injected.formErrors) return null
                return (injected.formErrors.value[formInputValue.value.__f] ?? null) as string | null
            })

            const isRequired = computed<boolean>(() => {
                return props.required ?? formInputValue.value?.__r ?? false
            })

            const isInvalid = computed<boolean | undefined>(() => {
                return props.ariaInvalid ?? errorMessage.value ? true : undefined
            })

            function handleFormErrorReset() {
                if (!injected.resetFormError || !formInputValue.value) return
                injected.resetFormError(formInputValue.value.__f)
            }

            function handleInputChange() {
                // handle .lazy v-model modifier
                if (inputValueModifiers.value['lazy']) {
                    lazyValue.value && setInputValue(lazyValue.value)
                }

                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'change',
                    __f: formInputValue.value.__f,
                })
            }

            function handleInputBlur() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'blur',
                    __f: formInputValue.value.__f,
                })
            }

            const textarea = ref<HTMLTextAreaElement | null>(null)
            const input = ref<HTMLInputElement | null>(null)

            function clearInput() {
                internalValue.value = isNumberInput.value ? 0 : ''
            }

            function handleAutoFocus() {
                if (!props.autofocus) return

                focusInput()
            }

            function handleWrapperClick(e: Event) {
                e.preventDefault()
                // TODO: figure out a way to select the input content on double click
                // textarea.value?.click()
                // input.value?.click()
                focusInput()
            }

            function focusInput() {
                textarea.value?.focus()
                input.value?.focus()
            }

            onMounted(() => {
                handleAutoFocus()
            })

            ctx.expose({
                focusInput,
            })

            // DX ATTRIBUTE CHECKS --------------------------------------------------------

            if (import.meta.dev) {

                if (props.type === 'password' && !props.autocomplete) {
                    throw new Error(
                        `The input type is set to 'password' but no autocomplete attribute is set. Use either 'current-password' or 'new-password'.`
                    )
                }

                if (props.type !== 'textarea') {
                    // the input is NOT a TEXTAREA

                    // rows attribute set on non-textarea input
                    if (props.rows !== 1) {
                        throw new Error(
                            `The input type is NOT set to 'textarea' but the 'rows' attribute is set.`
                        )
                    }
                } else {
                    // the input IS a TEXTAREA

                    // no rows attribute to set the initial state when using autoresize
                    // eslint-disable-next-line no-lonely-if
                    if (props.autoresize && !props.rows) {
                        throw new Error(
                            `The input type is set to 'textarea' and the 'autoresize' attribute is set but the 'rows' attribute is NOT set.
                            It is necessary to set the 'rows' attribute when using 'autoresize' to specify the initial state.`
                        )
                    }
                }
            }


            return () => (
                <div
                    class={['sim-input', baseUiElementGroupInjected?.classes.value, {
                        'sim-input--disabled': isDisabled.value,
                        'sim-input--loading': props.loading,
                        'sim-input--error': isInvalid.value,
                        [`c-${props.color}`]: props.color,
                        [`v-${props.variant}`]: props.variant,
                        [`s-${props.size}`]: props.size,
                        'sim-input--leading': isLeadingSlotRendered.value && props.paddingBehavior === 'dynamic',
                        'sim-input--trailing': isTrailingSlotRendered.value && props.paddingBehavior === 'dynamic',
                        'sim-input--square': props.square,
                        'sim-input--autoresize': props.autoresize,
                        'sim-input--textarea': props.type === 'textarea',
                    }]}
                    onClick={withModifiers(handleWrapperClick, ['self'])}
                >
                    {isLeadingSlotRendered.value && (
                        <div class="sim-input__sides"
                            aria-hidden={props.actions === 'leading' || props.actions === 'both' ? undefined : true}
                            style={props.actions === 'leading' || props.actions === 'both' ? undefined : 'pointer-events: none'}
                        >
                            {renderSlot(ctx.slots.leading, options?.slots?.leading, {})}
                        </div>
                    )}

                    {props.type === 'textarea'
                        ? <textarea
                            id={inputId.value}
                            v-model={internalValue.value}
                            ref={textarea}
                            class="sim-input__el"
                            rows={props.rows}
                            required={isRequired.value}
                            disabled={isDisabled.value}
                            placeholder={props.placeholder}
                            aria-describedby={describedBy.value}
                            aria-label={props.ariaLabel}
                            aria-invalid={isInvalid.value}
                            autocomplete={props.autocomplete}
                            onInput={handleFormErrorReset}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                        : <input
                            id={inputId.value}
                            v-model={internalValue.value}
                            ref={input}
                            type={inputType.value}
                            class="sim-input__el"
                            required={isRequired.value}
                            disabled={isDisabled.value}
                            placeholder={props.placeholder}
                            aria-describedby={describedBy.value}
                            aria-label={props.ariaLabel}
                            aria-invalid={isInvalid.value}
                            autocomplete={props.autocomplete}
                            min={props.min}
                            max={props.max}
                            step={props.step}

                            // disable autocorrect when the password is visible
                            autocapitalize={props.type === 'password' && inputType.value === 'text' ? 'none' : undefined}
                            spellcheck={props.type === 'password' && inputType.value === 'text' ? false : undefined}
                            autocorrect={props.type === 'password' && inputType.value === 'text' ? 'off' : undefined}

                            // autoresize TODO: improve (currently doesn't work well with fonts that aren't monospace)
                            style={props.autoresize ? `width: ${(internalValue.value || props.placeholder || 0)?.toString().length + 1}ch` : undefined}

                            onInput={handleFormErrorReset}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    }

                    {isTrailingSlotRendered.value && (
                        <div class="sim-input__sides"
                            aria-hidden={props.actions === 'trailing' || props.actions === 'both' || hasBuiltInActionsInTrailingSlot.value ? undefined : true}
                            style={props.actions === 'trailing' || props.actions === 'both' ? undefined : 'pointer-events: none'}
                        >
                            {(ctx.slots.trailing !== undefined || options?.slots?.trailing) && (
                                <div
                                    aria-hidden={props.actions === 'trailing' || props.actions === 'both' ? undefined : true}
                                    style={props.actions === 'trailing' || props.actions === 'both' ? undefined : 'pointer-events: none'}
                                >
                                    {renderSlot(ctx.slots.trailing, options?.slots?.trailing, {})}
                                </div>
                            )}

                            {props.clearable && (
                                <button
                                    type="button"
                                    style={hasContent.value ? undefined : 'visibility: hidden; pointer-events: none'}
                                    disabled={hasContent.value ? undefined : true}
                                    class="sim-input__clear-btn"
                                    aria-controls={inputId.value}
                                    aria-label={t('_core_simploshop.accessibility.clear_input_text')}
                                    onClick={clearInput}
                                >
                                    {renderSlot(ctx.slots.clear, options?.slots?.clear, {}, (
                                        // TODO: add icon
                                        <span>X</span>
                                    ))}
                                </button>
                            )}

                            {props.showPasswordToggle && (
                                <div>
                                    <div class="visually-hidden" aria-live="polite">
                                        {passwordVisibilityMessage.value}
                                    </div>

                                    <button
                                        type="button"
                                        class="sim-input__toggle"
                                        aria-controls={inputId.value}
                                        aria-pressed={isPasswordVisible.value}
                                        aria-label={isPasswordVisible.value ? t('_core_simploshop.accessibility.hide_password') : t('_core_simploshop.accessibility.show_password')}
                                        onClick={() => togglePasswordVisibility()}
                                    >
                                        {renderSlot(ctx.slots.passwordToggle, options?.slots?.passwordToggle, {
                                            isVisible: isPasswordVisible.value,
                                        }, (
                                            // TODO: add icon
                                            <>
                                                {isPasswordVisible.value ? t('_core_simploshop.accessibility.hide_password') : t('_core_simploshop.accessibility.show_password')}
                                            </>
                                        ))}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        },
        {
            props: getBaseUiInputRuntimeProps(options),
            slots: Object as SlotsType<BaseUiInputSlots<Colors, Variants, Sizes>>,
            emits: {
                'update:modelValue': (val: string | number) => true,
                'update:form': (val: FormFieldObject<string | number>) => true,
            },
        }
    )
}

export default defineComponentBaseUiInput()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiInput.scss" as *;

</style>
